<script setup>
import PopoverLogin from "~/layouts/LayoutHeader/PopoverLogin.vue";
import {useBaseStore} from "~/stores/base";
import {
  PATH_HOME,
  PATH_REGISTER,
  PATH_ACCOUNT,
  PATH_ACCOUNT_ACCOUNT_OVERVIEW,
  PATH_LOGIN,
  PATH_WISHLIST
} from "~/utils/constants";
import iconCrossWhite from '@/assets/img/icon-cross-white.svg'
import iconCloseBlack from '@/assets/img/icon-close-black.svg'
import iconMenu from "@/assets/img/icon-menu.svg"
import {Loading} from "@element-plus/icons-vue";
import ModuleSearch from "~/layouts/LayoutHeader/ModuleSearch.vue";

const route = useRoute()
const router = useRouter()

/** props **/
const props = defineProps({
  isFixed: {
    default: false
  },
  searchVisible: {
    default: false
  }
})

/** data **/
const isShowPopoverLogin = ref(false)

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const isLogin = computed(() => useBaseStore().getterIsLogin)
const userInfo = computed(() => useBaseStore().getterUserInfo)
const cartItemList = computed(() => useCartStore().getterCartItemList)
const isShowDrawerMenu = computed(() => useBaseStore().getterIsShowDrawerMenu)
const appInit = computed(() => useBaseStore().getterAppInit)
const hash = computed(() => route.hash)

/** methods **/
const handleLoginMouseover = (type) => {
  if (route.path === PATH_ACCOUNT) return
  if (route.path === PATH_LOGIN) return
  if (type === 'enter') {
    isShowPopoverLogin.value = true
  }
  if (type === 'leave') {
    isShowPopoverLogin.value = false
  }
}
const handleBNTOClick = () => {
  if (hash.value === '#cart') {
    // 获取当前路径，但去掉 hash 部分
    router.replace({
      path: router.currentRoute.value.path,
      query: router.currentRoute.value.query, // 保留查询参数
      hash: '' // 移除 hash
    });
  } else {
    router.replace({
      path: router.currentRoute.value.path,
      query: router.currentRoute.value.query, // 保留查询参数
      hash: '#cart'
    });
  }
}
const handleMenuClick = () => {
  // 获取当前路径，但去掉 hash 部分
  router.replace({
    path: router.currentRoute.value.path,
    query: router.currentRoute.value.query, // 保留查询参数
    hash: '' // 移除 hash
  }).then(() => {
    useBaseStore().updateIsShowDrawerMenu(!isShowDrawerMenu.value)
  });
}
const handleBNTOAIClick = () => {
  if (hash.value === '#ai') {
    // 获取当前路径，但去掉 hash 部分
    router.replace({
      path: router.currentRoute.value.path,
      query: router.currentRoute.value.query, // 保留查询参数
      hash: '' // 移除 hash
    });
  } else {
    router.replace({
      path: router.currentRoute.value.path,
      query: router.currentRoute.value.query, // 保留查询参数
      hash: '#ai'
    });
  }
}
</script>

<template>
  <div class="home-header-mobile" :class="{isFixed}" v-if="isMobile">
    <div class="header-top">
      <div class="top-left">
        <img class="icon" :src="isShowDrawerMenu ? iconCloseBlack : iconMenu" alt="icon" @click="handleMenuClick">
        <nuxt-link :to="PATH_HOME" @click="handleLogoClick">
          <img class="logo" src="@/assets/img/icon-logo-header.svg" alt="icon">
        </nuxt-link>
      </div>
      <!-- 已登录 -->
      <div class="top-right" v-if="isLogin">
        <nuxt-link :to="PATH_ACCOUNT_ACCOUNT_OVERVIEW" class="button-right button-account"
                   :class="{active: route.path.includes(PATH_ACCOUNT)}">
          <img src="@/assets/img/icon-user-filled-white.svg" alt="user" v-if="route.path.includes(PATH_ACCOUNT)">
          <img src="@/assets/img/icon-user-filled.svg" alt="user" v-else>
        </nuxt-link>
        <nuxt-link :to="PATH_WISHLIST" class="button-right button-wishlist"
                   :class="{active: route.path === PATH_WISHLIST}">
          <img src="@/assets/img/icon-heart-filled-white.svg" alt="heart" v-if="route.path === PATH_WISHLIST">
          <img src="@/assets/img/icon-heart-filled.svg" alt="heart" v-else>
        </nuxt-link>
        <!-- loading -->
        <div class="button-right button-BNTO" :class="{active: hash === '#cart'}" v-if="userInfo.bntoIcon === -1">
          <el-icon class="is-loading" color="#292929" size="16">
            <Loading/>
          </el-icon>
        </div>
        <template v-else>
          <!-- Digit -->
          <div class="button-right button-BNTO" :class="{active: hash === '#cart'}" v-if="userInfo.bntoIcon === 0"
               @click="handleBNTOClick">
            {{ cartItemList.length }} / {{ userInfo.bntoUpperLimit }}
          </div>
          <!-- Cube -->
          <div class="button-right button-BNTO" :class="{active: hash === '#cart'}" v-if="userInfo.bntoIcon === 1"
               @click="handleBNTOClick">
            <img src="@/assets/img/icon-cube-white.svg" alt="cube" v-if="hash === '#cart'">
            <img src="@/assets/img/icon-cube.svg" alt="cube" v-else>
          </div>
          <!-- Locked -->
          <div class="button-right button-BNTO" :class="{active: hash === '#cart'}" v-if="userInfo.bntoIcon === 2"
               @click="handleBNTOClick">
            <img src="@/assets/img/icon-locked-white.svg" alt="locked" v-if="hash === '#cart'">
            <img src="@/assets/img/icon-locked.svg" alt="locked" v-else>
          </div>
        </template>
      </div>
      <!-- 未登录 -->
      <div class="top-right" v-else>
        <nuxt-link class="button-right button-join-now" :to="PATH_REGISTER">
          <img class="flash" src="@/assets/img/icon-flash.svg" alt="flash">
          {{ $t('headerJoinNow') }}
        </nuxt-link>
        <nuxt-link class="button-right button-login" :to="PATH_ACCOUNT">
          <div class="button-login-content">
            <img src="@/assets/img/icon-user-filled.svg" alt="user">
          </div>
        </nuxt-link>
      </div>
    </div>
    <div class="header-bottom" v-if="searchVisible">
      <div class="button-ai" :class="{active: hash === '#ai'}" @click="handleBNTOAIClick">
        <img class="icon-ai" src="@/assets/img/icon-ai.svg" alt="icon" v-if="hash === '#ai'"/>
        <div class="animate-ai-sparkle" v-else/>
      </div>
      <module-search/>
    </div>
  </div>
  <div class="home-header-desktop" :class="{isFixed}" v-else>
    <div class="header-left">
      <nuxt-link class="logo" :to="PATH_HOME">
        <img src="@/assets/img/logo-header-pink.png" alt="logo">
      </nuxt-link>
      <div class="buttons">
        <div class="button-left" :class="{active: isShowDrawerMenu}" @click="handleMenuClick">
          <img class="icon" :src="isShowDrawerMenu ? iconCrossWhite : iconMenu" alt="icon">
          {{ $t('headerMenu') }}
        </div>
        <module-search/>
      </div>
    </div>
    <!-- 已登录 -->
    <div class="header-right" v-if="isLogin">
      <div class="button-right button-ai" :class="{active: hash === '#ai'}" @click="handleBNTOAIClick">
        <img class="icon-ai" src="@/assets/img/icon-ai.svg" alt="icon" v-if="hash === '#ai'"/>
        <div class="animate-ai-sparkle" v-else/>
        BNTO AI
      </div>
      <nuxt-link :to="PATH_ACCOUNT_ACCOUNT_OVERVIEW" class="button-right button-account"
                 :class="{active: route.path.includes(PATH_ACCOUNT)}">
        {{ $t('headerAccount') }}
        <img src="@/assets/img/icon-user-white.svg" alt="user" v-if="route.path.includes(PATH_ACCOUNT)">
        <img src="@/assets/img/icon-user.svg" alt="user" v-else>
      </nuxt-link>
      <nuxt-link :to="PATH_WISHLIST" class="button-right button-wishlist"
                 :class="{active: route.path === PATH_WISHLIST}">
        {{ $t('headerWishlist') }}
        <img src="@/assets/img/icon-heart-white.svg" alt="heart" v-if="route.path === PATH_WISHLIST">
        <img src="@/assets/img/icon-heart.svg" alt="heart" v-else>
      </nuxt-link>
      <!-- loading -->
      <div class="button-right button-BNTO" :class="{active: hash === '#cart'}" v-if="userInfo.bntoIcon === -1">
        <el-icon class="is-loading" color="#292929" size="12">
          <Loading/>
        </el-icon>
      </div>
      <template v-else>
        <!-- Digit -->
        <div class="button-right button-BNTO" :class="{active: hash === '#cart'}" v-if="userInfo.bntoIcon === 0"
             @click="handleBNTOClick">
          BNTO {{ cartItemList.length }} / {{ userInfo.bntoUpperLimit }}
        </div>
        <!-- Cube -->
        <div class="button-right button-BNTO" :class="{active: hash === '#cart'}" v-if="userInfo.bntoIcon === 1"
             @click="handleBNTOClick">
          BNTO
          <img src="@/assets/img/icon-cube-white.svg" alt="cube" v-if="hash === '#cart'">
          <img src="@/assets/img/icon-cube.svg" alt="cube" v-else>
        </div>
        <!-- Locked -->
        <div class="button-right button-BNTO" :class="{active: hash === '#cart'}" v-if="userInfo.bntoIcon === 2"
             @click="handleBNTOClick">
          BNTO
          <img src="@/assets/img/icon-locked-white.svg" alt="locked" v-if="hash === '#cart'">
          <img src="@/assets/img/icon-locked.svg" alt="locked" v-else>
        </div>
      </template>
    </div>
    <!-- 未登录 -->
    <div class="header-right" v-else>
      <div class="button-right button-ai" :class="{active: hash === '#ai'}" @click="handleBNTOAIClick">
        <img class="icon-ai" src="@/assets/img/icon-ai.svg" alt="icon" v-if="hash === '#ai'"/>
        <div class="animate-ai-sparkle" v-else/>
        BNTO AI
      </div>
      <nuxt-link class="button-right button-how-it-works" :to="appInit.agreementLink.howItWorksLink">
        {{ $t('headerHowItWorks') }}
      </nuxt-link>
      <nuxt-link class="button-right button-join-now" :to="PATH_REGISTER">
        <img class="flash" src="@/assets/img/icon-flash.svg" alt="flash">
        {{ $t('headerJoinNow') }}
      </nuxt-link>
      <div
        class="anchor"
        @mouseenter="handleLoginMouseover('enter')"
        @mouseleave="handleLoginMouseover('leave')"
      >
        <nuxt-link class="button-right button-login" :to="PATH_ACCOUNT">
          <div class="button-login-content">
            <img src="@/assets/img/icon-user-filled.svg" alt="user">
          </div>
        </nuxt-link>
        <popover-login :open="isShowPopoverLogin"/>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.home-header-mobile {
  width: 100%;
  // 不能放这里，否则首页有遮挡
  //position: relative;
  //z-index: 4;

  .header-top {
    background-color: white;
    width: 100%;
    height: 58px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    position: relative;
    z-index: 4;

    .top-left {
      display: flex;
      align-items: center;
      cursor: pointer;

      a {
        display: flex;
      }

      .icon {
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }

      .logo {
        width: 84px;
        height: 16px;
      }
    }

    .top-right {
      display: flex;
      align-items: center;

      .button-right {
        border: 0.5px $color-BNTO-beige-dark solid;
        height: 42px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      .button-join-now {
        //styleName: Button/small;
        font-family: Druk Wide Cy;
        font-size: 9px;
        font-weight: 500;
        line-height: 10px;
        text-align: center;

        color: $color-gray-800;
        background-color: $color-BNTO-beige-light;
        padding: 0 8px;

        display: flex;
        align-items: center;

        .flash {
          width: 15px;
          height: 15px;
          margin-right: 2px;
        }
      }

      .button-login {
        padding: 0 20px;
        position: relative;

        .button-login-content {
          height: 100%;
          display: flex;
          align-items: center;

          img {
            width: 20px;
            height: 20px;
            opacity: .6;
          }
        }
      }

      .button-search {
        padding: 0 20px;

        img {
          width: 20px;
          height: 20px;
        }
      }

      .button-account {
        padding: 0 20px;

        img {
          width: 20px;
          height: 20px;
        }
      }

      .button-wishlist {
        padding: 0 20px;

        img {
          width: 20px;
          height: 20px;
        }
      }

      .button-BNTO {
        padding: 0 20px;
        background-color: $color-BNTO-beige-light;

        //styleName: Button/large;
        font-family: Druk Wide Cy;
        font-size: 12px;
        font-weight: 500;
        line-height: 10px;
        text-align: center;

        img {
          width: 16px;
          height: 16px;
        }

      }

      .active {
        background-color: $color-gray-800;
        color: white;
      }
    }
  }

  .header-bottom {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 6px;

    padding: 8px;
    background-color: $color-gray-100;

    .button-ai {
      background-color: white;
      flex-shrink: 0;
      width: 36px;
      height: 36px;
      box-shadow: 0 0 0 0.5px $color-BNTO-beige-dark;

      display: flex;
      justify-content: center;
      align-items: center;

      .animate-ai-sparkle {
        width: 30px;
        height: 30px;

        background: url("@/assets/img/animate-ai-sequence-frame-30.png") no-repeat;
        background-position-x: 0;
        background-position-y: 0;
        background-size: cover;
        animation: moveBackgroundMobile 1.99998s steps(60) infinite; /* 60 * 33.33ms = 1.9998s */
      }

      @keyframes moveBackgroundMobile {
        0% { background-position-y: 0; }
        100% { background-position-y: -1800px; } /* 60 * -30px = -1800px */
      }

      .icon-ai {
        width: 24px;
        height: 24px;
      }
    }

    .active {
      background-color: $color-gray-800;
      color: white;
    }
  }
}

.home-header-desktop {
  background-color: white;
  width: 100%;
  min-width: $content-1280-width;
  height: $desktop-header-height;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  position: relative;
  z-index: 4;

  .header-left {
    display: flex;

    .logo {
      display: flex;
      cursor: pointer;

      img {
        width: 140px;
        height: 48px;
      }
    }

    .buttons {
      align-self: flex-end;
      display: flex;

      .button-left {
        cursor: pointer;
        height: 32px;

        border: 0.5px solid $color-BNTO-beige-dark;

        display: flex;
        justify-content: center;
        align-items: center;

        padding: 4px 12px;

        //styleName: Text sm/Regular;
        font-family: TWK Lausanne;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        text-align: left;

        .icon {
          width: 24px;
          height: 24px;
          margin-right: 4px;
          flex-shrink: 0;
        }
      }

      .active {
        background-color: $color-gray-800;
        color: white;
      }
    }
  }

  .header-right {
    display: flex;
    padding-top: 16px;

    .anchor {
      margin-left: 10px;
      position: relative;
    }

    .button-right {
      height: 32px;
      border: 0.5px solid $color-BNTO-beige-dark;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .button-ai {
      /* Text sm/Regular */
      font-family: "TWK Lausanne";
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 20px; /* 142.857% */
      padding: 4px 12px;
      display: flex;
      align-items: center;
      gap: 4px;
      margin-right: 10px;

      .animate-ai-sparkle {
        width: 24px;
        height: 24px;

        background: url("@/assets/img/animate-ai-sequence-frame.png") no-repeat;
        background-position-x: 0;
        background-position-y: 0;
        background-size: cover;
        animation: moveBackground 1.9998s steps(60) infinite; /* 60 * 33.33ms = 1.9998s */
      }

      @keyframes moveBackground {
        0% { background-position-y: 0; }
        100% { background-position-y: -1440px; } /* 60 * -24px = -1440px */
      }

      .icon-ai {
        width: 24px;
        height: 24px;
        padding: 2.5px;
      }
    }

    .button-account {
      //styleName: Text sm/Regular;
      font-family: TWK Lausanne;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      text-align: left;

      padding: 0 12px;

      img {
        width: 24px;
        height: 24px;
        margin-left: 4px;
      }
    }

    .button-wishlist {
      //styleName: Text sm/Regular;
      font-family: TWK Lausanne;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      text-align: left;

      padding: 0 12px;

      img {
        width: 24px;
        height: 24px;
        margin-left: 4px;
      }
    }

    .button-BNTO {
      min-width: 98px;
      background-color: $color-BNTO-beige-light;
      //styleName: Button/large;
      font-family: Druk Wide Cy;
      font-size: 12px;
      font-weight: 500;
      line-height: 10px;

      padding: 2px 12px 0 12px;

      display: flex;
      align-items: center;
      gap: 8px;

      i {
        width: 100%;
      }

      img {
        width: 16px;
        height: 16px;
        margin-top: -2px;
      }
    }

    .button-how-it-works {
      //styleName: Text sm/Regular;
      font-family: TWK Lausanne;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      text-align: left;

      padding: 0 12px;
    }

    .button-join-now {
      background-color: $color-BNTO-beige-light;
      //styleName: Button/small;
      font-family: Druk Wide Cy;
      font-size: 9px;
      font-weight: 500;
      line-height: 10px;
      text-align: center;

      padding: 0 8px;
      margin-left: 10px;

      display: flex;
      align-items: center;

      .flash {
        width: 12px;
        height: 12px;
        margin-right: 4px;
      }
    }

    .button-login {
      .button-login-content {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 20px;

        img {
          width: 20px;
          height: 20px;
          opacity: .6;
        }
      }
    }

    .active {
      background-color: $color-gray-800;
      color: white;
    }
  }
}

.isFixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4; // 不能去掉，否则Mobile 滚动遮挡
}
</style>
